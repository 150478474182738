import React, { useState, useEffect } from "react";
import PosTable from "../postable";
import SideBar from "../sidebar";
import Waiters from "../waiters";
import Halls from "../halls";
import tableIcon from "../../../assets/images/table3.png";

const MOBILE_SIZE = 836;
const SMALL_RES = 768;

function PosTables({ posAllTables, posAllWaiters, posAllHalls, activePos }) {
  const posTables = posAllTables;
  const numOfTables = posAllTables.length;

  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
  const [posWaiters, setPosWaiters] = useState([]);
  const [filterTables, setFilterTables] = useState([]);
  const [btnNumPerPage, setBtnNumPerPage] = useState(30);
  const [minTable, setMinTable] = useState(0);
  const [maxTable, setMaxTable] = useState(numOfTables);
  const [btnSize, setBtnSize] = useState([200, 120, 10]);
  const [activeWaiter, setActiveWaiter] = useState([0, "Active Waiter"]);
  const [activeHall, setActiveHall] = useState(["0", "All"]);
  const [modalWaiters, setModalWaiters] = useState(false);
  const [modalHalls, setModalHalls] = useState(false);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize]);

  useEffect(() => {
    const widthSize = window.innerWidth;
    const buttonSize =
      widthSize > MOBILE_SIZE
        ? windowSize[1] > SMALL_RES
          ? [200, 120, 10]
          : [150, 87, 5]
        : [75, 75, 5];
    setBtnSize(buttonSize);
    const btnPerPage =
      Math.floor((windowSize[1] - 70) / (buttonSize[1] + 2 * buttonSize[2])) *
      (windowSize[1] > SMALL_RES
        ? widthSize > 1872
          ? 7
          : widthSize > 1608
          ? 6
          : widthSize > 1344
          ? 5
          : widthSize > 1080
          ? 4
          : 3
        : widthSize > 1752
        ? 9
        : widthSize > 1560
        ? 8
        : widthSize > 1368
        ? 7
        : widthSize > 1176
        ? 6
        : widthSize > 984
        ? 5
        : 4);
    const buttonNumPerPage = widthSize > MOBILE_SIZE ? btnPerPage : numOfTables;
    const minTables = 0;
    const maxTables = buttonNumPerPage;
    setMinTable(minTables);
    setMaxTable(maxTables);
    setBtnNumPerPage(buttonNumPerPage);
    const filterHallTables =
      activeHall[0] > 0 && posTables.length > 0
        ? posTables.filter((posTable) => posTable.hallcode === activeHall)
        : posTables;
    setFilterTables(filterHallTables);
    setPosWaiters(posAllWaiters);
  }, [windowSize, activeHall, numOfTables, posTables, posAllWaiters]);

  const getActiveWaiter = (e, modalCall) => {
    setActiveWaiter([e.currentTarget.id, e.currentTarget.title]);
    modalCall && setModalWaiters(false);
  };

  const getActiveHall = (e) => {
    setActiveHall([e.currentTarget.id, e.currentTarget.title]);
    setModalHalls(false);
  };

  const showModalWaiters = (closeModal) => {
    setModalWaiters(closeModal);
  };

  const showModalHalls = (closeModal) => {
    setModalHalls(closeModal);
  };

  const showBtnPages = (allTables) => {
    const posTables = allTables;
    if (!(posTables.length > 0)) {
      return null;
    }

    const pagesNum = posTables.length / btnNumPerPage;
    const ypoloipo = pagesNum - Math.floor(pagesNum);
    const fixedPagesNum = ypoloipo > 0 ? Math.floor(pagesNum) + 1 : Math.floor(pagesNum);
    const btns = [];
    for (var i = 0; i < fixedPagesNum; i++) {
      const min = (i + 1) * btnNumPerPage - btnNumPerPage + 1;
      const max =
        (i + 1) * btnNumPerPage < posTables.length ? (i + 1) * btnNumPerPage : posTables.length;
      btns.push(
        <button
          key={i + 1}
          onClick={() => {
            setMinTable(min - 1);
            setMaxTable(max);
          }}
          style={{
            width: "100%",
            height: "50px",
            margin: "10px",
            overflow: "hidden",
            backgroundColor: "#68a0cf",
            borderRadius: 10,
            borderWidth: 0,
            cursor: "pointer",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{
                width: "36px",
                height: "36px",
                marginRight: "10px",
              }}
              src={tableIcon}
              alt=""
            />
            <h3>
              {min} .. {max}
            </h3>
          </div>
        </button>
      );
    }
    return btns;
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
      <div style={{ width: "100%" }}>
        {windowSize[0] > MOBILE_SIZE ? (
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            {showBtnPages(posTables)}
          </div>
        ) : (
          <div style={{ position: "fixed", top: 0, width: "100%", backgroundColor: "white" }}>
            <SideBar
              posWaiters={posWaiters}
              activeWaiter={activeWaiter}
              activeHall={activeHall}
              mobile={true}
              smallRes={!(windowSize[1] > SMALL_RES)}
              onClickWaiters={showModalWaiters}
              onClickHalls={showModalHalls}
              onClickWaiter={getActiveWaiter}
            />
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            marginTop: windowSize[0] > MOBILE_SIZE ? "0px" : "65px",
          }}
        >
          {filterTables?.length > 0 &&
            filterTables.slice(minTable, maxTable).map((posTable) => {
              return (
                <div key={posTable.TableNo}>
                  <PosTable
                    posTable={posTable}
                    activeWaiter={activeWaiter}
                    buttonSize={btnSize}
                    mobile={!(windowSize[0] > MOBILE_SIZE)}
                    smallRes={windowSize[1] < SMALL_RES}
                  />
                </div>
              );
            })}
        </div>
      </div>
      {windowSize[0] > MOBILE_SIZE && (
        <div style={{ width: "20%", marginRight: "25px" }}>
          <SideBar
            posWaiters={posWaiters}
            activeWaiter={activeWaiter}
            activeHall={activeHall}
            mobile={false}
            smallRes={windowSize[1] < SMALL_RES}
            onClickWaiters={showModalWaiters}
            onClickHalls={showModalHalls}
            onClickWaiter={getActiveWaiter}
          />
        </div>
      )}
      {modalWaiters && (
        <Waiters
          posWaiters={posWaiters}
          mobile={!(windowSize[0] > MOBILE_SIZE)}
          smallRes={windowSize[1] < SMALL_RES}
          activePos={activePos}
          onClickWaiters={showModalWaiters}
          onClickWaiter={getActiveWaiter}
        />
      )}
      {modalHalls && (
        <Halls
          posHalls={posAllHalls}
          mobile={!(windowSize[0] > MOBILE_SIZE)}
          smallRes={windowSize[1] < SMALL_RES}
          activePos={activePos}
          onClickHalls={showModalHalls}
          onClickHall={getActiveHall}
        />
      )}
    </div>
  );
}

export default PosTables;
