import React, { useState, useEffect } from "react";
import Waiter from "../waiter";
import waiterIcon from "../../../assets/images/waiter96.png";

function SideBar({
  posWaiters,
  activeWaiter,
  activeHall,
  mobile,
  smallRes,
  onClickWaiters,
  onClickHalls,
  onClickWaiter,
}) {
  const [waiters, setWaiters] = useState([[posWaiters]]);

  useEffect(() => {
    const filteredWaiters =
      posWaiters.length > 0 ? posWaiters.filter((posWaiter) => posWaiter.Active === 1) : posWaiters;
    setWaiters(filteredWaiters);
  }, [posWaiters]);

  return (
    <div
      style={{
        display: mobile ? "flex" : "grid",
        justifyContent: mobile ? "space-evenly" : "none",
        gap: mobile ? "0.5rem" : "none",
        margin: mobile ? "10px" : "0px",
      }}
    >
      <button
        onClick={onClickWaiters}
        style={{
          width: "100%",
          height: "50px",
          margin: !mobile ? "10px" : "0px",
          marginLeft: !mobile ? (smallRes ? "5px" : "10px") : "0px",
          overflow: "hidden",
          backgroundColor: "#68a0cf",
          borderRadius: 10,
          borderWidth: 0,
          cursor: "pointer",
        }}
      >
        {!mobile ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{
                width: "24px",
                height: "24px",
                marginRight: "5px",
              }}
              src={waiterIcon}
              alt="waiter"
            />
            <h3>Waiters</h3>
          </div>
        ) : activeWaiter[0] === 0 ? (
          <h4>Waiters</h4>
        ) : (
          <h4>{activeWaiter[1]}</h4>
        )}
      </button>
      {!mobile && (
        <button
          id={0}
          title="Active Waiter"
          onClick={onClickWaiter}
          style={{
            width: "100%",
            height: "85px",
            margin: smallRes ? "5px" : "10px",
            overflow: "hidden",
            backgroundColor: "#2F4F4F",
            color: "white",
            borderRadius: 10,
            borderWidth: 0,
            cursor: "pointer",
          }}
        >
          <h2>{activeWaiter[1]}</h2>
        </button>
      )}
      {!mobile &&
        waiters.length > 0 &&
        waiters.map((waiter) => {
          return (
            <div key={waiter.Code}>
              <Waiter
                posWaiter={waiter}
                onClickWaiter={onClickWaiter}
                mobile={mobile}
                smres={smallRes}
                modalCall={false}
              />
            </div>
          );
        })}
      <button
        disabled={true}
        onClick={() => {}}
        style={{
          width: "100%",
          height: "50px",
          margin: !mobile ? (smallRes ? "5px" : "10px") : "0px",
          marginTop: !mobile ? (smallRes ? "10px" : "20px") : "0px",
          overflow: "hidden",
          backgroundColor: "#EFDFBB",
          borderRadius: 10,
          borderWidth: 0,
          cursor: "pointer",
        }}
      >
        {!mobile ? <h3>POS</h3> : <h4>POS</h4>}
      </button>
      <button
        id={activeHall[0]}
        title={activeHall[1]}
        onClick={onClickHalls}
        style={{
          width: "100%",
          height: "50px",
          margin: !mobile ? (smallRes ? "5px" : "10px") : "0px",
          overflow: "hidden",
          backgroundColor: "#EFDFBB",
          borderRadius: 10,
          borderWidth: 0,
          cursor: "pointer",
        }}
      >
        {activeHall[0] === "0" ? (
          mobile ? (
            <h4>{activeHall[1]}</h4>
          ) : (
            <h3>Hall ({activeHall[1]})</h3>
          )
        ) : mobile ? (
          <h4>{activeHall[1]}</h4>
        ) : (
          <h3>{activeHall[1]}</h3>
        )}
      </button>
      {!mobile && (
        <button
          disabled={true}
          onClick={() => {}}
          style={{
            width: "100%",
            height: "50px",
            margin: smallRes ? "5px" : "10px",
            marginTop: smallRes ? "10px" : "20px",
            overflow: "hidden",
            backgroundColor: "#D09BBE",
            borderRadius: 10,
            borderWidth: 0,
            cursor: "pointer",
          }}
        >
          <h3>Merge Tables</h3>
        </button>
      )}
      {!mobile && (
        <button
          disabled={true}
          onClick={() => {}}
          style={{
            width: "100%",
            height: "50px",
            margin: smallRes ? "5px" : "10px",
            overflow: "hidden",
            backgroundColor: "#D09BBE",
            borderRadius: 10,
            borderWidth: 0,
            cursor: "pointer",
          }}
        >
          <h3>Room Info</h3>
        </button>
      )}
    </div>
  );
}

export default SideBar;
