import React from "react";

function Hall({ posHall, onClickHall, mobile, smallRes }) {
  return (
    <button
      id={posHall.Code}
      title={posHall.Description}
      onClick={onClickHall}
      style={{
        width: mobile ? "100px" : "150px",
        margin: smallRes ? "15px" : "0px",
        marginBottom: smallRes ? "15px" : "20px",
      }}
    >
      {!mobile ? (
        smallRes ? (
          <h4>{posHall.Description}</h4>
        ) : (
          <h3>{posHall.Description}</h3>
        )
      ) : (
        <h5>{posHall.Description}</h5>
      )}
    </button>
  );
}

export default Hall;
