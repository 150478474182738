import React from "react";
import Waiter from "../waiter";
import "./waiters.css";
import waiterIcon from "../../../assets/images/waiter96.png";

function Waiters({ posWaiters, mobile, smallRes, activePos, onClickWaiters, onClickWaiter }) {
  return (
    <div className="modalBackground">
      <div className="modalContainer" style={{ width: mobile ? "300px" : "500px" }}>
        <div className="title">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{
                width: "36px",
                height: "36px",
                marginRight: "10px",
              }}
              src={waiterIcon}
              alt="waiter"
            />
            <h2>Waiters</h2>
          </div>
        </div>
        <div className="body">
          {
            /*!mobile &&*/
            posWaiters?.length > 0 &&
              posWaiters.map((waiter) => {
                return (
                  <Waiter
                    posWaiter={waiter}
                    onClickWaiter={onClickWaiter}
                    mobile={mobile}
                    smres={smallRes}
                    modalCall={true}
                  />
                );
              })
          }
        </div>
        <div className="footer">
          <button style={{ fontSize: mobile ? "14px" : "18px" }}>Check In</button>
          <button style={{ fontSize: mobile ? "14px" : "18px" }}>Check Out</button>
          <button
            onClick={() => {
              onClickWaiters(false);
            }}
            id="cancelBtn"
            style={{ fontSize: mobile ? "14px" : "18px" }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default Waiters;
