import React from "react";
import "./postable.css";
import waiterIcon from "../../../assets/images/waiter96.png";
import emptyIcon from "../../../assets/images/empty96.png";

function PosTable({ posTable, activeWaiter, buttonSize, mobile, smallRes }) {
  const activeWaiterCode = parseInt(activeWaiter[0], 10);

  return (
    <div>
      <button
        className="container"
        id={posTable.TableNo}
        style={{
          width: buttonSize[0],
          height: buttonSize[1],
          margin: buttonSize[2],
          backgroundColor: posTable.Active
            ? posTable.TableStatus === 2
              ? "#FFD700"
              : "#50C878"
            : "#cccccc",
          paddingTop: mobile ? "5px" : "3px",
        }}
      >
        <div
          className="subTopContainer"
          style={{
            padding: mobile ? "1px" : "5px",
          }}
        >
          <div
            class="numberCircle"
            style={{
              width: mobile ? "20px" : "38px",
              height: mobile ? "20px" : "38px",
              fontSize: mobile ? "12px" : "20px",
              marginTop: mobile ? "0px" : "2px",
            }}
          >
            {posTable.TableNo}
          </div>
          {posTable.FolioCharged > 0 && (
            <div
              style={{
                width: mobile ? "26px" : "48px",
                height: mobile ? "14px" : "22px",
                fontSize: mobile ? "12px" : "18px",
              }}
              class="roomBox"
            >
              {posTable.RoomCharged}
            </div>
          )}
        </div>

        <div
          className="subBottomContainer"
          style={{
            paddingLeft: mobile ? "8px" : "0px",
          }}
        >
          <img
            style={{ width: mobile ? "26px" : "48px", height: mobile ? "26px" : "48px" }}
            src={
              activeWaiterCode === posTable.ActiveWaiter && posTable.Active ? waiterIcon : emptyIcon
            }
            alt="waiter"
          />
        </div>
      </button>
    </div>
  );
}

export default PosTable;
