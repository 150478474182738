import React, { useState, useEffect, useCallback } from "react";
import Axios from "axios";
import PosTables from "./components/postables";

const URL = "https://api.fbw.webcubes.eu:10603/FBWService.svc/v1/";
const MINUTE_MS = 30000;

function Tables() {
  const activePos = 1;
  //const [activePos, setActivePos] = useState(1);
  const [posAllTables, setPosAllTables] = useState([]);
  const [posAllWaiters, setPosAllWaiters] = useState([]);
  //const [posAllPos, setPosAllPos] = useState([]);
  const [posAllHalls, setPosAllHalls] = useState([]);

  useEffect(() => {
    getAllTables();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllWaiters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllHalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllTables = useCallback(async () => {
    try {
      await Axios.get(`${URL}tables/pos/${activePos}?versioncheck=false`).then((res) => {
        if (res?.data) setPosAllTables(res.data);
      });
    } catch (err) {
      alert(err.message);
    }
  }, [activePos]);

  const getAllWaiters = async () => {
    try {
      await Axios.get(`${URL}waiters/pos/${activePos}?versioncheck=false`).then((res) => {
        if (res?.data) setPosAllWaiters(res.data);
      });
    } catch (err) {
      alert(err.message);
    }
  };

  /*const getAllPos = async () => {
        try {
          await Axios.get(`${URL}pos?versioncheck=false`).then((res) => {
            //console.log(res.data);
            if (res?.data) setPosAllWaiters(res.data);
          });
        } catch (err) {
          alert(err.message);
        }
      };*/

  const getAllHalls = async () => {
    try {
      await Axios.get(`${URL}halls/pos/${activePos}?versioncheck=false`).then((res) => {
        if (res?.data) {
          setPosAllHalls(res.data);
        }
      });
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getAllTables();
      console.log(`Logs every ${MINUTE_MS / 1000} seconds`);
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [getAllTables]);

  return (
    <PosTables
      posAllTables={posAllTables}
      posAllWaiters={posAllWaiters}
      posAllHalls={posAllHalls}
      activePos={activePos}
    />
  );
}

export default Tables;
