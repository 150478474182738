import React from "react";
import "./waiter.css";

function Waiter({ posWaiter, onClickWaiter, mobile, smallRes, modalCall }) {
  return (
    <button
      id={posWaiter.Code}
      title={posWaiter.Name}
      onClick={posWaiter.Active ? (event) => onClickWaiter(event, modalCall) : null}
      style={{
        width: modalCall ? (mobile ? "85px" : "105px") : "100%",
        margin: modalCall ? "0px" : smallRes ? "15px" : "10px",
        backgroundColor: posWaiter.Active ? "#50C878" : "#BBBBBB",
        marginBottom: modalCall ? "20px" : smallRes ? "15px" : "10px",
        cursor: "pointer",
      }}
    >
      {!mobile ? (
        modalCall || smallRes ? (
          <h4>{posWaiter.Name}</h4>
        ) : (
          <h3>{posWaiter.Name}</h3>
        )
      ) : (
        <h5>{posWaiter.Name}</h5>
      )}
    </button>
  );
}

export default Waiter;
