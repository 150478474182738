import React from "react";
import Hall from "../hall";
import "./halls.css";

function Halls({ posHalls, mobile, smallRes, activePos, onClickHalls, onClickHall }) {
  const fixedPosHalls = [{ Code: 0, Description: "All", PosCode: activePos }, ...posHalls];
  return (
    <div className="modalBackground">
      <div className="modalContainer" style={{ width: mobile ? "300px" : "500px" }}>
        <div className="title">
          <h2>Halls</h2>
        </div>
        <div className="body">
          {
            /*!mobile &&*/
            fixedPosHalls?.length > 0 &&
              fixedPosHalls.map((hall) => {
                return (
                  <Hall posHall={hall} onClickHall={onClickHall} mobile={mobile} smres={smallRes} />
                );
              })
          }
        </div>
        <div className="footer">
          <button
            onClick={() => {
              onClickHalls(false);
            }}
            id="cancelBtn"
            style={{ fontSize: mobile ? "14px" : "18px" }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default Halls;
